type MetaData = {
	title: string
	generator: string
	description: string
	keywords: string
	robots: string
	ogLocale: string
	ogSiteName: string
	ogType: string
	ogTitle: string
	ogDescription: string
	ogImage: string
	ogImageWidth: string
	ogImageHeight: string
	ogUrl: string
	twitterCard: string
	canonical: string
	home: string
	jsonLd: string
}

type SitemapGlobalSettings = {
	enabled: boolean
}

export function setMetaTags(seomatic: any, pathname = ''): MetaData {
	const title = extractMetaContent(
		seomatic.metaTitleContainer,
		/<title>(.*?)<\/title>/,
	)
	const generator = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta name="generator" content="(.*?)">/,
	)
	const description = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta name="description" content="(.*?)">/,
	)
	const keywords = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta name="keywords" content="(.*?)">/,
	)
	const robots = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta name="robots" content="(.*?)">/,
	)

	const ogLocale = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:locale">/,
	)
	const ogSiteName = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:site_name">/,
	)
	const ogType = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:type">/,
	)
	const ogTitle = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:title">/,
	)
	const ogDescription = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:description">/,
	)
	const ogImage = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:image">/,
	)
	const ogImageWidth = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:image:width">/,
	)
	const ogImageHeight = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:image:height">/,
	)
	const ogUrl = extractMetaContent(
		seomatic.metaTagContainer,
		/<meta content="([^"]+)" property="og:url">/,
	)

	const twitterCard = extractMetaContent(
		seomatic?.metaTagContainer,
		/<meta name="twitter:card" content="([^"]+)">/,
	)

	const canonical = extractMetaContent(
		seomatic.metaLinkContainer,
		/<link href="([^"]+)" rel="canonical">/,
	)
	const home = extractMetaContent(
		seomatic.metaLinkContainer,
		/<link href="([^"]+)" rel="home">/,
	)

	var jsonLd = extractMetaContent(
		seomatic.metaJsonLdContainer,
		/<script type="application\/ld\+json">(.*?)<\/script>/s,
	)
		
	if (jsonLd && pathname) {
		const jsonLdObject = JSON.parse(jsonLd);

		const updatedJsonLd = JSON.stringify(jsonLdObject).replace(
		new RegExp(ogUrl, 'g'),
		home + pathname,
		);

		jsonLd = updatedJsonLd
	}

	return {
		title,
		generator,
		description,
		keywords,
		robots,
		ogLocale,
		ogSiteName,
		ogType,
		ogTitle,
		ogDescription,
		ogImage,
		ogImageWidth,
		ogImageHeight,
		ogUrl,
		twitterCard,
		canonical,
		home,
		jsonLd,
	}
}

function extractMetaContent(container: string, regex: RegExp): string {
	const match = container.match(regex)
	return match ? match[1] : ''
}
